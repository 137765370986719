<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item>常见问题</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="menu-area">
        <el-button type="primary" @click="toAdd">添加常见问题</el-button>
      </div>
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="title" label="问题标题"></el-table-column>
        <el-table-column prop="view" label="被查看次数"></el-table-column>
        <el-table-column fixed="right" label="操作" width="330">
          <template slot-scope="scope">
            <el-button size="small" type="success" @click="editItem(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const { data: res } = await this.$http.get('/pc-faq-list')
      if (res.status === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    editItem (id) {
      this.$router.push('/admin/apps/pc/faq/edit/' + id)
    },
    async delItem (id) {
      const { data: res } = await this.$http.delete('/pc-faq/', { params: { id: id } })
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getData()
      } else {
        this.$message.error(res.msg)
      }
    },
    toAdd () {
      this.$router.push('/admin/apps/pc/faq/add')
    }
  }
}
</script>

<style scoped>
.menu-area{
  padding: 0 0 20px 0;
  text-align: right;
}
.faq-img{
  max-width: 100px;
  max-height: 50px;
}
</style>
